#cms-content {
  //max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  * {
    font-family: futura-pt, sans-serif;
    line-height: 1;
    font-weight: 400;
  }
  .font-weight-500 {
    font-weight: 500;
  }
  .font-weight-600 {
    font-weight: 600;
  }
  .margin-bottom-25 {
    margin-bottom: 25px;
  }
  .margin-bottom-40 {
    margin-bottom: 40px;
  }
  .line-height-1 {
    line-height: 1;
  }
  .line-height-1-5 {
    line-height: 1.5;
  }
  .margin-bottom-30 {
    margin-bottom: 30px;
  }
  .margin-bottom-25 {
    margin-bottom: 25px;
  }
  .padding-sides-10 {
    padding-left: 10px;
    padding-right: 10px;
  }
  .wp-block-button__width-100 {
    width: 100%;
    a {
      width: 100%;
      text-align: center;
    }
  }
  .cms-block-wrap {
    max-width: 800px; //TODO rollback before push
    margin: auto;
    padding-left: 15px;
    padding-right: 15px;
    .post-cards-wrap {
      width: 100%;
      margin: auto;
      max-width: 1391px;
      margin-top: 16px;
      .wp-block-group__inner-container {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        flex-wrap: wrap;
        @media (max-width: 790px) {
          justify-content: center;
        }
        .post-card {
          margin: 15px;
          box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 20%), 0px 1px 1px 0px rgba(0, 0, 0, 14%), 0px 1px 3px 0px rgba(0, 0, 0, 12%);
          border-radius: 4px;
          background-color: #424242;
          color: white;
          max-width: 315px;
          width: 100%;
          min-height: 453px;
          @media (max-width: 677px) {
            margin-left: 0;
            margin-right: 0;
          }
          .post-content-wrap {
            width: 100%;
            & > .wp-block-group__inner-container {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              justify-content: flex-start;
            }
          }
          .post-title, .post-title a {
            font-size: 1.5rem;
            font-family: "Roboto", "Helvetica", "Arial", sans-serif;
            font-weight: 400;
            line-height: 1.334;
            letter-spacing: 0em;
            color: white;
            text-decoration: none;
            text-overflow: ellipsis;
            max-width: 281px;
            // white-space: nowrap;
            display: block;
            // overflow: hidden;
            text-align: left;
            @media (max-width: 400px) {
              max-width: 212px;
            }
            p {
              margin-bottom: 12px;
              margin-top: 27px;
            }
          }
          .post-sub-title, .post-sub-title a {
            text-decoration: none;
            font-size: 0.875rem;
            font-family: "Roboto", "Helvetica", "Arial", sans-serif;
            font-weight: 400;
            line-height: 1.43;
            letter-spacing: 0.01071em;
            color: white;
            text-overflow: ellipsis;
            max-width: 281px;
            //white-space: nowrap;
            display: block;
            //overflow: hidden;
            @media (max-width: 400px) {
              max-width: 212px;
            }
            p {
              margin: 0;
              margin-bottom: 10px;
            }
          }
          .post-title,
          .post-sub-title {
            margin: 0;
          }
          .post-title {
            margin-bottom: 7px;
          }
          .post-icon figure {
            display: flex;
            margin: 0;
            max-height: 176px;
            width: 100%;
            img {
              width: 100%;
            }
            a {
              display: flex;
              width: 100%;
            }
          }
          .wp-block-buttons {
            .wp-block-button__link {
              background-color: transparent;
              border: unset;
              box-shadow: unset;
              padding: 16px;
            }
          }
          padding-bottom: 16px;
        }
      }
    }
    .titles-wrap {
      padding-left: 16px;
      padding-right: 16px;
      //height: 65px;
      .wp-block-group__inner-container {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        flex-wrap: nowrap;
      }
    }
    .wp-block-cover {
      position: relative;

      .wp-block-cover__image-background {
        width: 100%;
        height: auto;
        object-fit: fill;
        filter: brightness(.5);
      }

      .wp-block-cover__inner-container {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0;
        width: 100%;
        height: 98%;
        color: white;
        font-family: inherit;
        //background-color: rgba(0, 0, 0, 0.4);
      }
    }

    .wp-block-buttons {
      .wp-block-button {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .wp-block-button__link, button {
      text-decoration: none;
      font-size: 0.875rem;
      color: #FFF;
      box-shadow: 0px 2px 0px 1px rgba(0, 0, 0, .2);
      //box-shadow: 12px 12px 2px 1px rgba(0, 0, 255, .2);
      background-color: #005695;
      padding: 6px 16px;
      overflow: hidden;
      min-width: 64px;
      box-sizing: border-box;
      transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      font-family: futura-pt, sans-serif;
      font-weight: 500;
      line-height: 1.3;
      padding-top: 11px;
      padding-left: 21px;
      border-radius: 30px;
      padding-right: 21px;
      padding-bottom: 11px;
      text-transform: uppercase;
      cursor: pointer;
      border: unset;
    }

    .wp-block-gallery {
      padding-top: 20px;
      padding-bottom: 20px;
      width: 100%;
      margin: unset;

      .blocks-gallery-grid {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: center;

        .blocks-gallery-item {
          list-style: none;

          figure {
            margin-left: unset;
            margin-right: 15px;
            max-width: 220px;
            //width: auto;
            height: auto;
          }

          img {
            width: 100%;
          }
        }
      }
    }

    .wp-video-wrap {
      width: 100%;
      margin: unset;
    }

    .has-text-align-center {
      text-align: center;
    }

    .has-large-font-size {
      font-size: 34px;
    }

    .wp-block-table {
      td {
        color: #666666;
        font-size: 16px;
        text-align: left;
      }
    }

    h2 {
      color: #00437C;
      font-family: inherit;
      font-size: 34px;
      text-align: center;
    }

    h1 {
      font-size: 42px;
      text-align: center;
      color: #00437C;
    }

    .wp-block-code {
      padding-top: 25px;

      button {
        margin-top: 15px;
      }

      .ap3w-form-input {

      }

      .ap3w-embeddable-form {
        overflow: unset;
      }

      .ap3w-embeddable-form-content {
        width: 100%;
      }

      label {
        margin-bottom: 5px;
        display: block;
        font-size: 16px;
      }

      .ap3w-text {
        margin-bottom: 30px;
      }

      input {
        font: inherit;
        color: currentColor;
        width: 100%;
        border: 0;
        height: 35px;
        margin: 0;
        display: block;
        min-width: 0;
        background: none;
        box-sizing: content-box;
        animation-name: mui-auto-fill-cancel;
        -webkit-tap-highlight-color: transparent;
        border: 1px solid rgba(0, 0, 0, 0.87);
        border-radius: 4px;
        padding: 5px 0;
        padding-left: 10px;
        font-size: 16px;
      }
    }
  }
  .blog-page-icons-wrap {
    max-width: 1391px;
    padding-left: unset;
    padding-right: unset;
    @media (max-width: 1478px) {
      max-width: 1035px;
    }
    @media (max-width: 1133px) {
      max-width: 690px;
    }
  }
  .cms-block-wrap-service {
    max-width: unset;
    margin-right: unset;
    margin-left: unset;
    padding-left: unset;
    padding-right: unset;
  }
   .has-text-align-left {
     text-align: left;
   }
  .has-text-align-right {
    text-align: right;
  }
  .aligncenter {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .has-text-align-center {
    text-align: center;
  }
  .wp-block-image {
    margin: 0;
    .alignright {
      max-width: 50%;
      float: right;
      margin: .5em 0 .5em 1em;
    }
    .alignleft {
      max-width: 50%;
      float: left;
      margin: .5em 0 .5em 1em;
    }
    .aligncenter {
      margin-left: auto;
      margin-right: auto;
      clear: both;
    }
    img {
      @media (max-width: 600px) {
        width: 100%;
        height: auto;
      }
    }
  }
  .why-it-works-container {
    margin-left: 15px;
    padding-top: 18px;
    margin-right: 15px;
    margin-bottom: 25px;
    padding-bottom: 70px;
    margin-top: 0;
    border-radius: 30px;
    background-color: #FFF;
  }
  .wp-block-separator {
    background: none!important;
    border: none;
    text-align: center;
    max-width: none;
    line-height: 1;
    height: auto;
    clear: both;
    &.is-style-dots:after {
      content: "···";
      color: currentColor;
      font-size: 1.5em;
      letter-spacing: 2em;
      padding-left: 2em;
      font-family: serif;
    }
  }
  .wp-block-columns {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 600px) {
      flex-direction: column;
      align-items: center;
    }
  }
  .hero-container {
    height: 737px;
    overflow: hidden;
    position: relative;
    animation: backgroundAmination 20s linear infinite;
    background: linear-gradient(220deg, #005695, #5E33A2, #00437C, #0098CC);
    background-size: 600% 600%;
    position: relative;
    padding-top: 122px;
    top: -112px;
    & > .wp-block-group__inner-container {
      position: unset;
      @media (max-width: 600px) {
        display: flex;
        justify-content: center;
        height: 100%;
        .hero-images {
          margin-top: auto;
          margin-bottom: 42px;
        }
      }
    }
    @media (max-width: 600px) {
      padding-top: 45px;

    }
    &:before {
      content: " ";
      display: block;
      position: absolute;
      height: 52%;
      width: 100%;
      background-image: url("../img/mobile-divider.png");
      background-size: 100% auto;
      background-repeat: no-repeat;
      background-position: bottom;
      bottom: -36px;
      @media (max-width: 400px) {
        bottom: -20px;
      }
      @media (max-width: 350px) {
        bottom: 0px;
      }
      @media (min-width: 601px) {
        height: 50%;
        bottom: 0;
        background-size: 3000px auto;
        background-image: url("../img/hero-bg-texture.svg");
        background-position: center top;
      }
    }
  }
  .hero-block {
    //padding-top: 130px;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    padding: 30% 0 0 0;
    position: relative;
    max-width: 992px;
    box-sizing: border-box;
    text-align: center;
    align-items: flex-start;
    text-align: initial;
    height: 737px;
    position: absolute;
    width: 992px;
    left: 50%;
    margin-left: -496px;
    padding-top: 11%;
    @media (max-width: 1080px) {
      left: 50px;
      margin-left: unset;
    }
    @media (max-width: 900px) and (min-width: 601px) {
      left: 30px;
      max-width: 100%;
    }
  }
  .has-white-color {
    color: #fff;
  }
  @media (min-width: 601px) {
    .hero-mobile {
      display: none;
    }
  }
  @media (max-width: 600px) {
    .hero-mobile {
      width: 100%;
      bottom: 5%;
      height: 287px;
      max-width: 375px;
      margin: auto;
      //position: absolute;
      img {
        width: 100%;
      }
    }
    .hero-block {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      left: unset;
      right: unset;
      margin-left: unset;
      justify-content: flex-start;
      .hero-block-left-column {
        width: 100%;
        text-align: center;
        p {
          text-align: center;
        }
      }
    }
  }
  .hero-desktop {
    z-index: 1;
    position: absolute;
    width: 500px;
    height: 500px;
    left: 52%;
    top: 138px;
    .wp-block-image {
      margin: 0;
    }
    @media (max-width: 600px) {
      display: none;
    }
  }
  .hero-block-left-column {
    width: 50%;
    font-weight: 400;
    .wp-block-table {
      margin-left: 0;
      margin-right: 0;
      @media (max-width: 600px) {
        table {
          margin: auto;
          padding-left: 15px;
          padding-right: 15px;
          span {
            text-align: center;
            margin: auto;
            display: inline-block;
          }
        }
      }
    }
  }
  .start-for-free-removals-container {
    margin-left: 20px;
    padding-top: 38px;
    margin-right: 20px;
    padding-bottom: 70px;
    background-color: #F2E6FF;
    border-radius: 30px;
    & > .wp-block-group__inner-container {
      margin: auto;
      max-width: 1200px;
      .pricing-plans-container {
        & > .wp-block-column {
          padding: 15px;
          //position: relative;
          //margin-right: 30px;
          //margin-left: 30px;
          //&:after {
          //  content: ' ';
          //  width: 2px;
          //  height: 176px;
          //  right: -31px;
          //  top: 50%;
          //  margin-top: -88px;
          //  margin-bottom: 0;
          //  background-color: #1F88AB;
          //  position: absolute;
          //}
        }
        //& > .wp-block-column:first-child {
        //  margin-left: 0;
        //}
        //& > .wp-block-column:last-child {
        //  margin-right: 0;
        //  &:after {
        //    content: none;
        //  }
        //}
        @media (max-width: 600px) {
          flex-direction: row;
        }
      }
    }
    @media (max-width: 600px) {
      margin-right: 0px;
      margin-left: 0px;
      padding-bottom: 40px;
    }
  }
  .why-it-works-item {
    margin-top: 40px;
    figure {
      max-width: 220px;
      height: auto;
      margin: 0;
      min-width: 33%;
      @media (min-width: 601px) {
        margin-right: 15px;
      }

      img {
        max-width: 100%;
        height: auto;
      }
      //@media (max-width: 960px) {
      //  max-width: 158px;
      //  img {
      //    max-width: 158px;
      //  }
      //}
    }
    & > .wp-block-group__inner-container {
      display: flex;
      align-items: center;
      @media (max-width: 960px) {
        flex-direction: column;
        justify-content: center;
      }
    }
    .description-wrap {
      display: flex;
      flex-direction: column;
      padding-left: 15px;
      padding-left: 15px;
      h1, p {
        text-align: left!important;
      }
      @media (max-width: 960px) {
        h1 {
          text-align: center!important;
        }
      }
    }
    @media (max-width: 600px) {
      & > .wp-block-group__inner-container {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        max-width: 313px;
        margin: auto;
        h1 {
          text-align: center!important;
        }
      }
    }
  }
  .hero-buttons-wrap {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @media (max-width: 600px) {
      justify-content: center;
      margin-bottom: 30px;
    }
  }

  .pricing-plans-container {
    display: flex;
    align-items: flex-start;
    justify-content:space-around;

    .features-list {
      padding: 24px 0 24px 0;
      li {
        font-size: 18px;
        font-weight: normal;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        list-style: none;
        margin-bottom: 14px;
        &:before {
          content: 'done';
          color: white;
          font-family: 'Material Icons';
          height: 21px;
          margin-right: 20px;
          background-color: rgb(28, 178, 230);
          border-radius: 50%;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          max-width: 21px;
        }
      }
    }
    .get-plan-button {
      max-width: 300px;
      margin: auto;
      margin-bottom: unset;
      margin-top: auto;
      width: 100%;
      .wp-block-button {
        .wp-block-button__link {
          width: 100%;
          text-align: center;
          //margin-bottom: 15px;
        }
      }
    }
    .pricing-plan-wrap {
      min-height: 404px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
    }
    @media (max-width: 600px) {
      .vertical-divider {
        display: none;
      }
      .slick-dots {
        li, button {
          background: none;
          box-shadow: none;
          font-size: 0;
          padding: 0;
        }
      }
      .pricing-plan-wrap {
        .wp-block-button {
          width: 100%;
        }
        .pricing-info-wrap, .features-list {
          max-width: 300px;
          //margin: auto;
        }
        @media (max-width: 600px) {
          padding-left: 15px;
          padding-right: 15px;
          margin-bottom: 15px;
        }
      }
    }
  }

  .horizontal-divider {
    width: 30px;
    height: 2px;
    margin-top: 14px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px;
    background-color: #1F88AB;
  }
  .pricing-plans-container {
    .first-pricing-plan {
      //&:after {
      //  content: '" "';
      //  width: 2px;
      //  height: 176px;
      //  margin-top: 54px;
      //  margin-left: 20px;
      //  margin-right: 20px;
      //  margin-bottom: 0;
      //  background-color: #1F88AB;
      //}
    }
    .vertical-divider {
        content: '" "';
        height: 176px;
        margin-top: 54px;
        margin-left: 20px;
        margin-right: 20px;
        margin-bottom: 0;
        background-color: #1F88AB;
        width: 2px;
        @media (max-width: 750px) {
          display: none;
        }
    }
    p {
      margin: 0;
    }
    .price-wrap {
      .wp-block-group__inner-container {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .pricing-info-wrap {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 10px;
      max-width: 265px;
      width: 100%;
      box-shadow: 0 1px 4px rgba(0, 0, 0, .3);
      box-sizing: border-box;
      text-align: center;
      border-radius: 15px;
      color: rgba(0, 0, 0, 0.87);
      transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      background-color: #fff;
      height: 93px;
    }
    .price-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      p {
        margin: 0;
      }
    }
  }
  .has-white-background-color {
    background-color: white!important;
  }
  .why-it-works-block {
    .slick-arrow {
      background-color: unset;
      box-shadow: none;
      border: unset;
      height: 45px;
      top: 151px;
      padding: 0;
    }
    .slick-prev {
      left: 0px;
    }
    .slick-next {
      right: 0px;
    }
    @media (max-width: 400px) {
      .slick-prev {
        left: -12px;
      }
      .slick-next {
        right: -12px;
      }
    }
  }
  .source-code-block {
    background-color: #ebedf0;
    padding: 1px 12px;
    border-radius: 8px;
  }
  .has-extra-large-font-size {
    font-size: 40px;
  }
  .has-normal-font-size {
    font-size: 20px;
  }
}
