#why-it-works {
  @media (min-width: 601px) {
    .slick-track {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%!important;
      .slick-slide {
        width: 49%!important;
        & > div {
          padding-right: 20px;
          padding-left: 20px;
        }
      }
    }
  }
  .slick-slider {
    .slick-arrow {
      top: 110px;
      z-index: 1;
    }
    .slick-prev {
      left: 20px;
      width: 27px;
      height: 45px;
      &:before {
        content: '';
        display: inline-block;
        background-image: url("../img/slider-arrow-left.svg");
        width: 27px;
        height: 45px;
        background-repeat: no-repeat;
      }
      @media (max-width: 340px) {
        left: 5px;
      }
    }
    .slick-next {
      right: 20px;
      width: 27px;
      height: 45px;
      &:before {
        content: '';
        display: inline-block;
        background-image: url("../img/slider-rarrow-right.svg");
        width: 27px;
        height: 45px;
      }
      @media (max-width: 340px) {
        right: 5px;
      }
    }
  }
}
#who-it-work-for, #start-for-free-removals, #how-calcumate-works-slider {
  .slick-slider {
    width: 100%;
    .slick-dots {
      & > li {
        border: 2px solid #00437C;
        border-radius: 50%;
        width: 21px;
        height: 21px;
        button:before {
          display: none;
        }
      }
      .slick-active {
        button {
          //display: flex;
          //justify-content: center;
          //align-items: center;
          &:before {
            display: block;
            font-size: 32px;
            content: '';
            width: 15px;
            height: 15px;
            background: #00437C;
            border-radius: 50%;
            margin: 3px;
          }
        }
      }
    }
  }
}
#how-calcumate-works-slider {
  .slick-slider {
    .slick-dots {
      left: 0;
      bottom: -45px;
      .slick-active ~ li {
        button {
          &:before {
            display: none;
          }
        }
      }
      li {
        button {
          &:before {
            display: block;
            font-size: 32px;
            content: '';
            width: 15px;
            height: 15px;
            background: #00437C;
            border-radius: 50%;
            margin: 3px;
          }
        }
      }
    }
  }
}
#demo-section {
  //background: transparent url("../img/squered-background.png") repeat center top;
  //background: transparent url(../img/isometric-grid.png) repeat center top;
}

@media (min-width: 430px) and (max-width: 600px) {
  #hero-content {
    padding: 3% 0 0 0;
  }
  #hero-content:before {
    bottom: 5%;
  }
}

#simple-menu-desktop .MuiMenu-paper {
  left: unset!important;
  right: 285px!important;
  top: 97px!important;
}

body {
  padding-right: unset!important;
  overflow-x: hidden!important;
  overflow-y: unset!important;
}

body > .categoryItem {
  z-index: 3;
  width: 490px;
  height: 72.5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
}
body > .categoryItem .MuiTableCell-body,
body > .fieldItem .MuiTableCell-body {
  border-bottom: unset;
}
body > .categoryItem .MuiTableCell-body button,
body > .fieldItem .MuiTableCell-body button, {
  margin-left: 10px;
}
body > .categoryItem .MuiTableCell-body:nth-child(1){
  width: 57.13px;
}
body > .categoryItem .MuiTableCell-body:nth-child(2),
body > .fieldItem .MuiTableCell-body:nth-child(2){
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: auto;
}

body > .fieldItem {
  z-index: 11;
  width: 490px;
  height: 72.5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
}

.fieldItem {
  background-color: white;
  .fieldEditWrap {
    display: flex;
    border: unset;
    align-items: center;
    padding-top: 8px;
    padding-bottom: 3px;
    button {
      min-width: 40px;
    }
  }
  .editCellWrap {
    padding-left: 15px;
    width: 50px;
    border-bottom: unset;
  }
  .dragButton-Cell {
    width: 20px;
    padding-right: 5px;
  }
  .fieldLabel {
    padding-left: 10px;
    border-bottom: unset;
    min-width: 350px;
  }
  .removeCellWrap {
    border: unset;
  }
}
#who-it-work-for {
  .slick-dots {
    bottom: 32px;
  }
  .slick-slide:first-child {
    background-color: #76CAE8;
  }
  .slick-slide {
    @media (max-width: 960px) and (min-width: 600px) {
      max-height: 315px;
    }
  }

  @media (max-width: 1100px) {
    .slick-dots {
      bottom: 18px;
    }
  }


  @media (max-width: 600px) {
    .slick-dots {
      bottom: 15px;
    }
  }
}
#start-for-free-removals {

  .slick-dots {
    bottom: 0px;
  }

  .slick-slide, .slick-track {
    @media (min-width: 601px) {
      width: unset!important;
    }
  }

  @media (max-width: 1100px) {
    .slick-dots {
      bottom: 0px;
    }
  }


  @media (max-width: 600px) {
    .slick-dots {
      bottom: -47px;
    }
  }
}
body > .item-element-d-n-d {
  z-index: 10000;
}

#service-menu-desktop {
  .MuiMenu-paper {
    top: 75px!important;
  }
}
#service-menu {
  .MuiMenu-paper {
    top: 165px!important;
  }
}
#pricing-plan-screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .pricing-plans-container {
    .slick-dots {
      li {
        width: 8px;
        height: 8px;
        margin-right: 8px;
        margin-left: 8px;
      }
      li:first-child {
        margin-left: 0px;
      }
      li:last-child {
        margin-right: 0px;
      }
      li button:before {
        width: 8px;
        height: 8px;
        color: #BFDCE6;
      }
      li.slick-active button:before {
        width: 8px;
        height: 8px;
        color: #00437C;
      }
    }
    @media (max-width: 1366px) {
      // .draggable {
      //   padding-top: 33px;
      // }
      .slick-slide {
        display: flex;
        //margin-left: 8px;
        //margin-right: 8px;
        //&:first-child {
        //  margin-left: 0;
        //}
        //&:last-child {
        //  margin-right: 0;
        //}
      }
      .slick-slide {
        margin: 0 6px;
      }

      // Fix external margins
      .slick-list {
        margin: 0 -6px;
      }
      .slick-slider {
        overflow: hidden;
      }
    }
    & > div {
      //min-width: 284px;
      // @media (min-width: 1366px) {
      //   width: 24%;
      // }
      //max-width: 400px;
      //  padding: 15px;
      //  text-align: center;
      //  box-shadow: -1px 1px 3px rgba(0,0,0,0.1);
      //  border-radius: 0;
      //  box-sizing: border-box;
      //@media (min-width: 850px) and (max-width: 900px) {
      //  min-width: 255px;
      //}
      //@media (max-width: 600px) {
      //  max-width: 300px;
      //  width: 100%;
      //}
      //@media (max-width: 849px) {
      //  max-width: 400px;
      //  width: 100%;
      //}
    }
  }
  #pricing-help-section {
    margin-top: 0;
  }
  // #pricing-help-section:before {
  //   content: none!important;
  // }
  .slick-dots {
    bottom: -42px;
    @media (min-width: 351px) and (max-width: 900px) {
      left: -13px;
      width: calc(100% - 60px);
    }
  }
}
.pricingPlansDots {

}
//pricing-help-section
